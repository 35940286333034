.works-desc {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 40px;
}
.works-desc .nav {
    width: 245px;
    padding: 6px 37px 0 18px;
    box-sizing: border-box;
    flex-shrink: 0;
}
.works-desc .inner {
    margin-top: -7px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    flex-shrink: 0;
    box-sizing: border-box;
}
.works-desc .prev {
    display: inline-block;
    width: 30px; height: 30px;
    background: url(../images/prev.png) no-repeat center center;
    background-size: auto 15px;
    transition: all .3s ease-in;
}
.works-desc .prev:hover {
    transform: translateX(-3px);
    opacity: .8;
}
.works-desc .home {
    display: inline-block;
    width: 30px; height: 30px;
    background:url(../images/grid.png) no-repeat center center;
    background-size: 15px auto;
    transition: all .3s ease-in;
}
.works-desc .home:hover {
    transform: scale(.9);
    opacity: .7;
}
.works-desc .next {
    display:inline-block;
    width: 30px; height: 30px;
    background: url(../images/next.png) no-repeat center center;
    background-size: auto 15px;
    transition: all .3s ease-in;
}
.works-desc .next:hover {
    transform: translateX(3px);
    opacity: .8;
}
.works-desc .prev.disabled,
.works-desc .prev.disabled:hover,
.works-desc .next.disabled,
.works-desc .next.disabled:hover {
    opacity: 0.4;
    transform: translateX(0);
}
.works-desc .main {
    display: flex;
    flex-flow: row nowrap;
}
.works-desc .profile {
    max-width: 740px;
    padding-right: 80px;
}
.works-desc .profile .title {
    margin-bottom: 25px;
    font-weight: 300;
}
.works-desc .profile .title-en {
    font-weight: 600;
}
.works-desc .profile .split {
    padding: 0 5px;
    font-size: 16px;
}
.works-desc .profile .en {
    margin-bottom: 25px;
}
.works-desc .profile img {
    width: 100%;
}
.works-desc .profile p {
    margin-bottom: 25px;
}
.works-desc .contributor {
    box-sizing: border-box;
    padding-top: 61px;
    min-width: 300px;
}
.works-desc .contributor li {
    line-height: 1.4;
}
.works-desc .cont-title {
    font-weight: bolder;
    padding-right: 6px;
}
.contributor em {
    font-style: normal;
    padding-right: 6px;
}
.picture {
    padding: 0 25px;
    box-sizing: content-box;
}
.picture img,
.picture video {
    width: 100%;
    display: block;
    margin-bottom: 10px;
}
@media (max-width: 575.98px) {
    .works-desc {
        display: block;
    }
    .works-desc .nav {
        width: 100%;
        padding: 6px 25px 30px;
    }
    .works-desc .title {
        font-size: 18px;
    }
    .works-desc .main {
        display: block;
    }
    .works-desc .profile {
        padding: 0 25px 25px;
        width: 100%;
        box-sizing: border-box;
    }
    .works-desc .contributor {
        box-sizing: border-box;
        min-width: unset;
        padding: 0 25px 25px;
    }
    .picture img,
    .picture video {
        margin-bottom: 3px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .works-desc .main {
        display: block;
    }
    .works-desc .contributor {
        padding: 25px 0 0;
        min-width: unset;
    }
   
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .works-desc .main {
        display: block;
    }
    .works-desc .contributor {
        padding: 25px 0 0;
    }

}

@media (min-width: 992px) and (max-width: 1199.98px) {
   
}

@media (min-width: 1200px) {
    
}