.notfound {
    text-align: center;
    color: #999;
    line-height: 24px;
}
.notfound .pic img {
    width: 143px;
    margin-bottom: 24px;
}
.notfound .en {
    font-size: 18px;
    padding-bottom: 6px;
}