#root {
    padding-top:150px;
}
.g-header {
    position: fixed;
    z-index: 100;
    top: 0;left:0;
    padding: 16px 0 0 25px;
    height: 60px;
    background: #0a0a0a;
    line-height: 1.5;
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
}
.g-header img {
    display: block;
}
.g-header .ct-logo {
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
}
.g-header .ct-logo:hover {
    opacity: .8;
}
.g-header .logo {
    width: 26px;
}
.g-header .ct-title {
    margin-right: 43px;
    display: inline-block;
    vertical-align: top;
}
.g-header .ct-title:hover {
    opacity: .8;
}
.g-header .title {
    width: 130px;
}
.g-header .slogan {
    font-size: 13px;
    display: inline-block;
    margin-top: -4px;
    vertical-align: top;
    margin-right: 28px;
}
.g-header .contact {
    margin-top: -4px;
    display: inline-block;
    vertical-align: top;
    font-size: 13px;

}
.g-header .ct-about {
    position: absolute;
    right: 25px;
    top: 10px;
    font-size: 18px;
}
.g-header .bt-menu {
    display: none;
}
@media (max-width: 575.98px) {
    .g-header {
        height: 64px;
    }
    .g-header .slogan {
        display: none;
    }
    .g-header .contact {
        display: none;
    }
    .g-header .ct-about {
        display: none;
    }
    .g-header .ct-main {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }
    .g-header .btn-menu {
        display: inline-block;
        margin-right: 16px;
    }
    .g-header .icon {
        display: block;
        width: 18px;
		height: 2px;
		border-top: 2px solid #fff;
		border-bottom: 2px solid #fff;
		background-color: #fff;
		padding: 4px 0;
        background-clip:content-box;

    }
    .g-header .nav-work {
        display: inline-block;
        width: 18px; height: 18px;
        background:url(../images/grid.png) no-repeat center center;
        background-size: 15px auto;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .g-header .slogan {
        display: none;
    }
    .g-header .contact {
        display: none;
    }
   
}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {
   
}

@media (min-width: 1200px) {
    
}