.about-item {
    display: flex;
    box-sizing: border-box;
    flex-flow: row nowrap;
    margin: 0 0 50px 0;
}
.about-item dt {
    margin: 0;
    width: 245px;
    padding-left: 73px;
    padding-top: 5px;
    font:18px 'gilroy-extrabold';
    flex-shrink: 0;
    box-sizing: border-box;
}
.about-item dd {
    margin: 0;
    font-size: 18px;
    max-width: 740px;
    box-sizing: border-box;
    padding-right: 25px;
}
/*adwards*/
.awards-list li {
    display: flex;
    flex-flow: row nowrap;
}
.awards-list .date {
    width: 58px;
    display: inline-block;
    flex-shrink: 0;
}
.awards-list .awards {
    display: inline-block;
}
.en-list {
    margin-bottom: 50px;
}

.client-li {
    display: flex;
    flex-flow: column wrap;
    max-height: 456px;
}
.client-li li {
    margin-right: 18px;
    font-size: 18px;
    line-height: 24px;
    flex-shrink: 0;
    min-width: 200px;
}


@media (max-width: 575.98px) {
    .about-item {
        display: block;
        padding: 0 25px;
        box-sizing: border-box;
    }
    .about-item dt {
        width: 100%;
        box-sizing: border-box;
        padding: 0 0 45px 0;
    }
    .about-item dd {
        padding-right: 0;
    }
    .client-li {
        display: block;
        max-height:unset;
    }
    
}

@media (min-width: 576px) and (max-width: 1000px) {
    .about-item {
        display: block;
        padding: 0 71px;
        box-sizing: border-box;
    }
    .about-item dt {
        width: 100%;
        box-sizing: border-box;
        padding: 0 0 45px 0;
    }
    .about-item dd {
        padding-right: 0;
    }
   
}
@media (min-width: 576px) and (max-width: 767.98px) {
   
}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {
   
}

@media (min-width: 1200px) {
    
}