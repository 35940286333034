@font-face {
    font-family: 'gilroy-light';
    src: url('../fonts/gilroy-light-webfont.woff2') format('woff2'),
         url('../fonts/gilroy-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'gilroy-extrabold';
    src: url('../fonts/gilroy-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/gilroy-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
html, body {
    background: #0a0a0a;
    color: #ebebeb;
    box-sizing: border-box;
    line-height: 1.5;
    font-family: "gilroy-light", PingFang SC,Lantinghei SC,Helvetica Neue,Helvetica,Arial,Microsoft YaHei,微软雅黑,STHeitiSC-Light,simsun,宋体,WenQuanYi Zen Hei,WenQuanYi Micro Hei,"sans-serif";
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
    font-weight: 300;
    word-break: break-word;
    overflow-x: hidden;
}
h1,h2,h3,h4,
p, ul,li,dl,dd {
    margin:0;
    padding:0;
}
li {
    list-style: none;
}
a {
    color: #ebebeb;
    text-decoration: none;
}
a:hover {
    color: #fff;
}
.g-anim {
    transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.g-font-light {
    font-family: 'gilroy-light';
    /* -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto; */
}
.g-font-bold {
    font-family: 'gilroy-extrabold';
}

@media (max-width: 575.98px) {
    
}

@media (min-width: 576px) and (max-width: 767.98px) {
   
}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {
   
}

@media (min-width: 1200px) {
    
}