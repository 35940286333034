.ct-works {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}
.ct-works li {
    /* width: 25%; */
    text-align: center;
    box-sizing: border-box;
    transition: all .3s ease-in;
}
.ct-works img {
    width: 100%;
    display: block;
    transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.worksimg-enter {
    opacity: 0;
}
.worksimg-active {
    opacity: 1;
    transition: opacity 300ms;
}
.worksimg-exit {
    opacity: 1;
}
.worksimg-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}
.ct-works .lk-works:hover img {
    opacity: .9;
    transform: scale(0.97);
}
.load-more {
    height:20px;
    width: 100%;
    text-align: center;
    color: #fff;
}

/* hone */
@media (max-width: 575.98px) {
    .ct-works li {
        width: 100%;
    }
    .ct-works img {
        width: 100%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .ct-works li {
        width: 50%;
    }

}

@media (min-width: 768px) and (max-width: 991.98px) {
    .ct-works li {
        width: 33.33%;
    }

}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .ct-works li {
        width: 25%;
    }
}

@media (min-width: 1200px) {
    .ct-works li {
        width: 360px;
    }
}